'use client'
import React, { FC } from 'react'

export const ProviderComposer: FC<{
  contexts: JSX.Element[]
  children: JSX.Element | JSX.Element[]
}> = ({ contexts, children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return contexts.reduceRight((kids: any, parent: any) => {
    return React.cloneElement(parent, { children: kids })
  }, children)
}
