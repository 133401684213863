'use client'

import { LazyMotion } from 'framer-motion'
import type { PropsWithChildren } from 'react'

import { ProviderComposer } from '../../components/common/ProviderComposer'
import { EventProvider } from './event-provider'
import { JotaiStoreProvider } from './jotai-provider'
import { PageScrollInfoProvider } from './page-scroll-info-provider'
import { MantineProvider } from './mantine-provider'

const loadFeatures = () =>
  import('./framer-lazy-feature').then(res => res.default)
const contexts: JSX.Element[] = [
  <JotaiStoreProvider key='jotaiStoreProvider' />,
  <LazyMotion features={loadFeatures} strict key='framer' />,
  <MantineProvider key='mantineThemeProvider' />
]
export function Providers({ children }: PropsWithChildren) {
  return (
    <>
      <ProviderComposer contexts={contexts}>
        {children as JSX.Element}
        <EventProvider key='eventProvider' />
        <PageScrollInfoProvider key='pageScrollInfoProvider' />
      </ProviderComposer>
    </>
  )
}
