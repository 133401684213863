'use client'

import { createTheme } from '@mantine/core'

import { Component } from 'react'
import { MantineProvider as Provider } from '@mantine/core'
import { useIsClient } from '../../hooks/use-is-client'

export const theme = createTheme({
  /* Put your mantine theme override here */
})

export const MantineProvider: React.FC<{ children?: Component }> = ({
  children
}) => {
  const isClient = useIsClient()
  return isClient ? (
    <Provider theme={theme}>{children as React.ReactNode}</Provider>
  ) : (
    <></>
  )
}
