import dayjs from 'dayjs'

import 'dayjs/locale/zh-cn'
import weekday from 'dayjs/plugin/weekday'
import { initApiClient } from '~/lib/request'

export const init = () => {
  dayjs.locale('zh-cn')
  dayjs.extend(weekday)
  initApiClient()
}
